// Home features - Overall Layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 6,
    $prefab: 3
  );

  // Home feature - Card Specific
  .homeFeature {
    @include card (
        $card-details-padding: $spacer $spacer #{$spacer * 3}
      );
    @include card-basic;
    // Responsive
    @media (max-width: map-get($breakpoints, lg)) {
      @include card (
        $card-details-padding: $spacer
      );
      @include card-basic;
    }
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 2
  );
}

// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-basic;
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}


//
// Specific listing page
//
// Specific listing - Colour setup
body[class*='-gr-'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='category-hot-topic'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='category-our-autism-expertise'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card (
    $card-details-background-colour: darken($_lime-green, 5%),
    $card-heading-colour: text-contrast($_lime-green),
    $card-summary-colour: text-contrast($_lime-green),
    $card-hover-details-background-colour: darken($_lime-green, 5%),
    $card-hover-heading-colour: text-contrast($_lime-green),
    $card-hover-summary-colour: text-contrast($_lime-green),
  );
  @include card-basic;
}


// Specific listing - Colour setup
body[class*='-bl-'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='category-about-us'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card (
    $card-details-background-colour: darken($_sky-blue, 5%),
    $card-heading-colour: text-contrast($_sky-blue),
    $card-summary-colour: text-contrast($_sky-blue),
    $card-hover-details-background-colour: darken($_sky-blue, 5%),
    $card-hover-heading-colour: text-contrast($_sky-blue),
    $card-hover-summary-colour: text-contrast($_sky-blue),
  );
  @include card-basic;
}

// // Specific listing - Colour setup
body[class*='-pu-'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='category-knowledge-hub'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='category-how-you-can-help'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='category-our-education-and-residential-provision'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='category-our-approach-to-learning'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='what-young-people-learn'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='pages-events-category-fundraising-events'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
   @include card (
      $card-details-background-colour: darken($_purple, 5%),
      $card-heading-colour: text-contrast($_purple),
      $card-summary-colour: text-contrast($_purple),
      $card-hover-details-background-colour: darken($_purple, 5%),
      $card-hover-heading-colour: text-contrast($_purple),
      $card-hover-summary-colour: text-contrast($_purple),
    );
    @include card-basic;
}

// Specific listing - Colour setup
body[class*='-pi-'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='category-join-our-team'] .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
body[class*='category'][class*='events']:not(.pages-events-category-fundraising-events) .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card (
    $card-details-background-colour: darken($_pink, 5%),
    $card-heading-colour: text-contrast($_pink),
    $card-summary-colour: text-contrast($_pink),
    $card-hover-details-background-colour: darken($_pink, 5%),
    $card-hover-heading-colour: text-contrast($_pink),
    $card-hover-summary-colour: text-contrast($_pink),
  );
  @include card-basic;
}


// Subsites
.subsiteFeed .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 2,
    $prefab: 2
  );
  .feedItem {
    @include card (
      $card-gap-width: 0,
      $card-details-background-colour: transparent,
      $card-heading-colour: $body-colour,
      $card-summary-colour: $body-colour,
      $card-box-shadow: none,
      $card-hover-details-background-colour: transparent,
      $card-hover-heading-colour: $body-colour,
      $card-hover-summary-colour: $body-colour,
      $card-hover-box-shadow: none,
      $card-details-padding: 0 $spacer #{$spacer * 4} #{$spacer * 4}
    );
    @include card-basic;
  }
}
