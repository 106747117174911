@media (min-width: $nav-breakpoint) {
.menuMain .mainCallToAction {
  display: none;
}
}

/* Homepage - Hide nav
@media (min-width: #{$nav-breakpoint + 1px}) {
  body.homepage:not(.subsite) .menuMain {
    display: none;
  }
} */


// Logo - Pseudo background
.pageHeader .headerContent {
  position: relative;

  .logo-pebble {
    position: absolute;
    margin: 0;
    width: calc(#{$logo-width} + 150px);
    height: 100%;
    left: calc(#{$logo-width / 2} + #{$site-bleed});
    top: 50%;
    transform: translate(-50%, -50%) scale(-1);
    background-image: url($assets-path + "pebble_white.svg");
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 0;

    @media (max-width: $logo-breakpoint) {
      width: calc(#{$logo-mobile-width} + 150px);
      left: calc(#{$logo-mobile-width / 2} + #{$site-bleed});
    }
  }
}

// Header element - Above pebble
.headerContent .header-search,
.headerContent .mainCallToAction {
  z-index: 1;
}


// Search icon
@media (min-width: #{$header-search-breakpoint + 1px}) {
  .headerContent .mainCallToAction ul.socialIcons {
    display: none;
  }
}


// Search bar - Placeholder
.header-search #siteSearch::placeholder {
  font-size: 0.759rem;
  font-weight: $font-weight-bold;
}


// Header search - Layout
.pageHeader .headerContent .header-search {
  z-index: 1;
  order: 9;
  flex-grow: 0;
  margin-left: $header-search-margin-right;
  margin-right: 0;
}


// Header buttons - Layout
.pageHeader .headerContent .mainCallToAction {
  z-index: 1;
  margin-left: auto;
}


// Header CTA buttons - Format
.mainCallToAction {
  .cta-button[class*='career'] {
    background-color: $_purple;

    &:hover {
      background-color: darken($_purple, 10%);
    }
  }

  .cta-button[class*='contact'] {
    background-color: lighten(#5E7F16, 10%);

    &:hover {
      background-color: #5E7F16;
    }
  }

  .cta-button[class*='portal'] {
    background-color: $_sky-blue;

    &:hover {
      background-color: darken($_sky-blue, 10%);
    }
  }
}


// Burger button
.menuMainAlt {
  width: $social-icons-width;
  height: $social-icons-height;
  border-radius: $social-icons-border-radius;

  &:hover,
  &.active {
    background-color: darken($burger-btn-background-colour, 10%);
  }
}


// Nav mobile - Remove max height
@media (max-width: $nav-breakpoint) {
  .menuMain.active {
    max-height: none !important;
  }
}


// Carousel - Pebble
.carouselDetailWrapper {
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    width: 810px;
    height: 810px;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%) rotate(-20deg);
    background-image: url($assets-path + "pebble_sky-blue.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;

    @media (max-width: $carousel-breakpoint) {
      background-image: url($assets-path + "carousel-mobile-pebble_sky-blue.svg");
      bottom: calc(100% - 0.5px);
      top: auto;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      background-position: center bottom;
    }
  }
}


// Carousel
body.homepage:not(.subsite):not(.page-no-banner) .carousel {
  padding-top: $card-gap-width;
  .carouselDetailWrapper {
    &:before {
      background-image: url($assets-path + "pebble_lime-green.svg");
      @media (max-width: $carousel-breakpoint) {
        background-image: url($assets-path + "carousel-mobile-pebble_lime-green.svg");
        bottom: calc(100% - 0.5px);
        top: auto;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        background-position: center bottom;
      }
    }
    ul {
      list-style: none;
      padding-inline-start: 0;
      ul {
        padding-left: 0;
      }
      li {
        margin: 2.5px 0;
      }
      a {
        cursor: pointer !important;
        color: $grey-dark;
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
      }
    }
  }
}


// Carousel - Page no banner
body.page-no-banner .carousel {
  display: none;
}


// Carousel - Transitions + Box shadow
.carouselSlide {
  transition: 0s all ease !important;
  box-shadow: 0 0 2.5px rgba($grey-light, 0.25);
}


// Home Feature - Overall card colour + Blob/Icon setup
.homeBox1 {
  a {
    color: inherit;
    text-decoration: none!important;
  }
}
.homeFeatures .homeFeature:not(.homeBox1) {
  @mixin card-colour-blob ($card-colour, $card-icon, $pebble-icon, $half-pebble-icon) {
    position: relative;
    overflow: hidden;
    transition: 500ms all ease;
    cursor: default !important;

    // Details background-color
    .homeFeatureDetailsWrapper,
    &:hover .homeFeatureDetailsWrapper {
      position: relative;
      background-color: darken($card-colour, 5%);
    }

    // Details - Text contrast
    .homeFeatureDetailsWrapper h2 a,
    &:hover .homeFeatureDetailsWrapper h2 a,
    .homeFeatureDetailsWrapper p,
    &:hover .homeFeatureDetailsWrapper p {
      color: text-contrast(darken($card-colour, 5%));
    }

    // Half pebble 
    .homeFeatureDetailsWrapper:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 20%;
      bottom: calc(100% - 1px);
      left: 50%;
      transform: translateX(-50%);
      background-image: url($assets-path + $half-pebble-icon);
      background-size: contain;
      background-position: bottom center;
      background-repeat: no-repeat;
      z-index: 0;
    }

    // Icon setup
    &:after {
      content: '';
      position: absolute;
      width: 45px;
      height: 45px;
      top: $spacer;
      right: $spacer;
      background-image: url($assets-path + $card-icon);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
      transition: 900ms all ease-in-out;
    }


    // List setup
    .homeFeatureDetailsWrapper ul {
      color: $white;
      cursor: default !important;
      list-style: none;
      padding-inline-start: $spacer / 2;
      margin-top: $spacer;
      margin-bottom: $spacer / 2;


      li {
        margin: 2.5px 0;
      }

      a {
        cursor: pointer !important;
        color: text-contrast(darken($card-colour, 5%));
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
      }

      // Desktop behavior
      @media (min-width: map-get($breakpoints, lg) + 1px) {
        display: block;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 600ms all ease-in-out;
        z-index: 3;
        padding: $card-details-padding;
      }
    }

    // Blob setup
    @media (min-width: map-get($breakpoints, lg) + 1px) {
      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        left: 35%;
        bottom: 35%;
        background-image: url($assets-path + $pebble-icon);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
        transform: translate(-50%, 50%) rotate(-180deg);
        transition: 600ms all ease-in;
        transition-delay: 200ms;
      }
    }

    // Hover state
    &:hover {
      cursor: default !important;

      // Show blob
      &:before {
        transform: translate(-50%, 50%) rotate(-80deg);
        transition: 600ms all ease-in;
        width: 520px;
        height: 520px;
      }

      // Show list
      .homeFeatureDetailsWrapper ul {
        transition: 600ms all ease-in-out;
        transition-delay: 200ms;
        opacity: 1;
      }

      // Icon size
      &:after {
        transform: scale(1.3);
        transition: 300ms all ease-in-out;
        transition-delay: 400ms;
      }
    }
  }

//  Home Feature - Specific card colour + Blob/Icon setup
  &.homefeaturecategory-colour-limegreen {
    @include card-colour-blob($card-colour: $_lime-green,
      $card-icon: "icon_multi.svg",
      $pebble-icon: "pebble_lime-green.svg",
      $half-pebble-icon: "half-pebble_lime-green-darker.svg"
    );
  }

  // Home Feature - Specific card colour + Blob/Icon setup
  &.homefeaturecategory-colour-skyblue {
    @include card-colour-blob($card-colour: $_sky-blue,
      $card-icon: "icon_multi.svg",
      $pebble-icon: "pebble_sky-blue.svg",
      $half-pebble-icon: "half-pebble_sky-blue-darker.svg"
    );
  }

  // Home Feature - Specific card colour + Blob/Icon setup
  &.homefeaturecategory-colour-purple {
    @include card-colour-blob($card-colour: $_purple,
      $card-icon: "icon_multi.svg",
      $pebble-icon: "pebble_purple.svg",
      $half-pebble-icon: "half-pebble_purple-darker.svg"
    );
  }

  // Home Feature - Specific card colour + Blob/Icon setup
  &.homefeaturecategory-colour-pink {
    @include card-colour-blob($card-colour: $_pink,
      $card-icon: "icon_multi.svg",
      $pebble-icon: "pebble_pink.svg",
      $half-pebble-icon: "half-pebble_pink-darker.svg"
    );
  }
}


// Homepage  Introduction - Layout
.homeFeatures .homeFeature.homeBox1 {
  flex: unset;
  padding: $spacer * 1.5;
  font-size: $font-size-h5;
  font-weight: 600;
  background-color: $grey-lightest;
  color: $body-colour;

  >*:last-child {
    margin: 0;
  }
}


// Category - Hide related content
body.PostCategory_related-content-disabled .associatedRelatedPosts {
  display: none;
}


// Bespoke sidebar
.associated.bespokeSidebar {

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-base;
  }

  ul {
    margin-block-start: 0;
    padding-inline-start: 0;
    margin-block-end: 0;
    list-style: none;

    li {
      font-size: $associated-item-heading-font-size;
      font-weight: $font-weight-bold;
      line-height: $headings-line-height;
      padding: 0.75rem 0;
    }
  }
}


// Sidebar title - Format
.associated>h2 {
  font-family: $font-family-base;
}


// Default sidebar - Format
.associated:not(.associatedBox) .associatedListing {
  h3 {
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
  }

  li {
    padding: 0.75rem 0;
  }

  ul {
    display: none;
  }
}



// Footer - Box shadow
.pageFooter {
  box-shadow: 0 0 2.5px rgba($grey-light, 0.25);
}


// Footer Newsletter
@media (min-width: map-get($breakpoints, md) + 1px) {
  .footerBox[class*="ewsletter"] {
    padding-right: $spacer;
  }
}

// Footer - Logos
.footerBox[class*="ogo"] {
  width: 100%;
  max-width: $footer-contents-max-width;
  margin: 0 auto;
  padding: $spacer 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  p.legal {
    font-weight: $font-weight-bold;
    text-align: center;
    flex: unset;
    width: 100%;
    margin: #{$spacer * 2} auto;
  }

  img {
    display: block;
    margin: 10px 20px;
    max-width: 140px;
    max-height: 65px;
  }
}


// Quick giving panel - Selected amount
.quickGivingPanel .donationAmount.donationSelected {
  box-shadow: inset 0px 0px 0px 3px $donate-colour;
}


// Quick giving panel - Amounts format
@media (min-width: map-get($breakpoints, md) + 1px) {
  .quickGivingPanel .formQuestion.donationAmount {
    min-height: 100px;
    min-width: 100px;
    border-radius: $social-icons-border-radius;
    align-items: center;

    .checkboxRadio {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.post .quickGivingPanel .button {
  background-color: $donate-colour;

  &:hover {
    background-color: darken($donate-colour, 10%);
  }
}


// Inner page headings
.post .contentBlock .postContent {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-family-base;
  }

  img,
  iframe {
    border-radius: $border-radius;
  }
}

// Inner page (and listing page) post titles on mobile

@media (max-width: map-get($breakpoints, lg)) {

  h1.title {
    font-size: 3em;
  }

}


// Inner page Bullet points


.post .postContent ul:not(.shareList):not(.commentsList):not(.commentsList--printed) li::marker {
  color: transparent !important;
  opacity: 0 !important;
}

.post .postContent ul:not(.shareList):not(.commentsList):not(.commentsList--printed) {
  padding-inline-start: calc(40px - 12.5px - 7.5px);
  li {
    margin: 2.5px 0;
  }
}

.post .postContent ul:not(.shareList):not(.commentsList):not(.commentsList--printed) li:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url($assets-path + "icon_multi.svg");
  background-position: left bottom;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 7.5px;
  vertical-align: baseline;
}

.associated ul.associatedListing li:nth-child(-n+4) {
  display: inline-flex;
  align-items: center;
  width: 100%;

  &:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    background-image: url($assets-path + "icon_multi.svg");
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 7.5px;
  }
}

// Totaliser - Format
.listedPost .appealFooterWrapper .totaliserBG {
  border-radius: $social-icons-border-radius;
}


// Appeals in carousel - Buttons
.carousel #totaliserActions .button.donate,
.carousel #totaliserActions .button.fundraise {
  background-color: darken($donate-colour, 10%);

  &:hover {
    background-color: darken($donate-colour, 17.5%);
  }
}


// // Header text - Layout
// .headerText {
//   width: 100%;
//   max-width: $post-content-max-width;
//   margin-left: auto;
//   margin-right: auto;
// }


// // Title - Pebble
// .headerText .title {
//   position: relative;
//   z-index: 1;
//   max-width: max-content !important;
//   margin-left: 0 !important;
//   &:before { 
//     content: '';
//     position: absolute;
//     right: 0;
//     top: 0;
//     width: 45px;
//     height: 45px;
//     transform: translate(50%, -50%);
//     background-image: url($assets-path + 'pebble_sky-blue.svg');
//     background-size: contain;
//     background-position: center;
//     background-repeat: no-repeat;
//     z-index: -1;
//   }
// }


// Post - Pebble background
@media (min-width: map-get($breakpoints, md) + 1px) {

  // Kill horizontal scroll
  body:not(.productsList):not(.productPage):not(.faqsList) article.post {
    overflow: hidden;
  }

  // Peebble setup
  .post>.contentBlockWrapper:not(.formDonatePage):not(.appealActionsWrapper) {
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 75%;
      width: 40vw;
      height: 40vw;
      transform: translateY(-50%);
      background-image: url($assets-path + 'pebble_sky-blue.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }
}

// Listing Post - Pebbble icon
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  position: relative;

  // Icon setup
  &:after {
    content: '';
    position: absolute;
    width: 45px;
    height: 45px;
    top: $spacer;
    right: $spacer;
    background-image: url($assets-path + "icon_multi.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    transition: 900ms all ease-in-out;
  }

  // Half pebble 
  [class^="listed"][class$="Text"] {
    position: relative;
  }

  [class^="listed"][class$="Text"]:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);
    background-image: url($assets-path + "half-pebble_sky-blue-darker.svg");
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
    z-index: 0;
    transition: 300ms all ease;
  }

  // Hover
  &:hover {

    // Icon
    &:after {
      transform: scale(1.3);
      transition: 900ms all ease-in-out;
    }

    [class^="listed"][class$="Text"]:before {
      width: 125%;
      height: 125%;
      transition: 300ms all ease;
    }
  }
}


// Inner post page - Banner layout
@media (min-width: #{$carousel-breakpoint + 1px}) {
  body.page-has-banner .post .headerWrapper,
  body.subsite .headerWrapperSubsite {
    max-width: calc(#{$container-max-width} - #{$site-bleed * 2});
    width: calc(100% - #{$site-bleed * 2});
    margin: 0 auto #{$spacer * 4};
    display: flex;
    flex-direction: row-reverse;
    border-radius: $carousel-border-radius;
    overflow: hidden;

    // Image
    .carousel {
      flex-grow: 1;
      margin: 0;
      padding: 0;

      .carouselSlide {
        border-radius: 0;
      }
    }

    // Text
    .headerText {
      width: $carousel-details-max-width;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: transparent;
      margin: 0;
      padding: $carousel-details-padding;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        width: 810px;
        height: 810px;
        top: 50%;
        left: 55%;
        transform: translate(-50%, -50%) rotate(-20deg);
        background-image: url($assets-path + "pebble_sky-blue.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1;
      }

      // Page title
      .title {
        padding: 0;
        margin: 0;
        color: text-contrast($_sky-blue);
      }

      .listingIntro {
        padding: 0;
        margin-top: $spacer * 0.5;
        color: text-contrast($_sky-blue);
      }

      // Breadcrumbs link
      .breadcrumbWrapper {
        padding: 0;
      }

      .breadcrumbWrapper a,
      .breadcrumbWrapper .breadcrumbItem:before {
        color: text-contrast($_sky-blue);
      }
    }
  }
}


// Category - Colour code
@mixin category-colour($focus-colour, $pebble-colour, $pebble-mobile-colour, $card-icon, $half-pebble-icon, $quote-colour) {

  // Breadcrumbs link
  .breadcrumbWrapper a {
    font-weight: $font-weight-bold;
    text-decoration: underline;
    color: darken($focus-colour, 5%);
  }

  .carouselSlideDetail {
    background-color: $focus-colour;
    .carouselSlideHeading a,
    .carouselSlideSummary {
      color: text-contrast($focus-colour);
    }
  }

  // Carousel colour
  .carouselDetailWrapper:before {
    background-image: url($assets-path + $pebble-colour);
  }

  // Carousel mobile
  @media (max-width: #{$carousel-breakpoint}) {
    .carouselDetailWrapper:before {
      background-image: url($assets-path + $pebble-mobile-colour)!important;
    }
    .carouselSlideDetail {
      background-color: $focus-colour;
    }
  }

  // Banner pebble
  .post .headerWrapper .headerText:before {
    background-image: url($assets-path + $pebble-colour);
  }

  @media (min-width: #{$carousel-breakpoint + 1px}) {
    // Page title + Breadcrumb - Colour
    body.page-has-banner .post .headerWrapper .headerText .title,
    body.page-has-banner .post .headerWrapper .headerText .breadcrumbWrapper a {
      color: text-contrast($focus-colour);
    }
  }

  // Post content
  .post .contentBlock .postContent,
  .listingIntro {

    // Buttons
    a[class*='button']:not(.donateBtn),
    #enquirySubmitButton {
      background-color: $focus-colour;
      color: text-contrast($focus-colour);

      &:hover {
        background-color: darken($focus-colour, 10%);
      }
    }

    // Blockquote
    blockquote {
      background-color: $focus-colour;
      color: text-contrast($focus-colour);

      &:before,
      &:after {
        background-image: url($assets-path + $quote-colour);
      }
    }

    // Social icons
    .shareList a {
      background-color: $focus-colour;
      color: text-contrast($focus-colour);

      &:hover {
        background-color: darken($focus-colour, 10%);
      }
    }
  }

  // Listing page carousel pebbble
  .carouselDetailWrapper:before {
    background-image: url($assets-path + $pebble-colour);
  }

  // Listing button back top
  .button.backToTop {
    background-color: $focus-colour;
    color: text-contrast($focus-colour);

    &:hover {
      background-color: darken($focus-colour, 10%);
    }
  }

  // Listed post - Icon
  .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem):after {
    background-image: url($assets-path + $card-icon);
  }

  // Listed post - Half pebble
  .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) [class^="listed"][class$="Text"]:before {
    background-image: url($assets-path + $half-pebble-icon);
  }

  // Peebble setup
  .post>.contentBlockWrapper:not(.formDonatePage):after {
    background-image: url($assets-path + $pebble-colour);
  }
}

// Category specific - Colour code
body.PostCategory_colour-lime-green,
body.homepage,
body.contact,
body[class*='-gr-'],
body[class*='category-hot-topic'] {
  @include category-colour($focus-colour: $_lime-green,
    $pebble-colour: "pebble_lime-green.svg",
    $pebble-mobile-colour: "carousel-mobile-pebble_lime-green.svg",
    $card-icon: "icon_multi.svg",
    $half-pebble-icon: "half-pebble_lime-green-darker.svg",
    $quote-colour: "quote-black.svg"
  );
  .breadcrumbWrapper a,
  .headerText .title {
    color: text-contrast($_lime-green)!important;
  }
}

// Category specific - Colour code
body.PostCategory_colour-sky-blue,
body[class*='-sb-'],
body[class*='category-about-us'],
body[class*='portal'],
body[class*='our-autism-expertise'],
body[class*='family-information'],
body.donate {
  @include category-colour($focus-colour: $_sky-blue,
    $pebble-colour: "pebble_sky-blue.svg",
    $pebble-mobile-colour: "carousel-mobile-pebble_sky-blue.svg",
    $card-icon: "icon_multi.svg",
    $half-pebble-icon: "half-pebble_sky-blue-darker.svg",
    $quote-colour: "quote-white.svg"
  );
}

// Category specific - Colour code
body.PostCategory_colour-pink,
body[class*='-pi-'],
body[class*='category'][class*='events']:not(.pages-events-category-fundraising-events),
body[class*='category-join-our-team'],
body.blogs {
  @include category-colour($focus-colour: $_pink,
    $pebble-colour: "pebble_pink.svg",
    $pebble-mobile-colour: "carousel-mobile-pebble_pink.svg",
    $card-icon: "icon_multi.svg",
    $half-pebble-icon: "half-pebble_pink-darker.svg",
    $quote-colour: "quote-white.svg"
  );
}

// Category specific - Colour code
body.PostCategory_colour-purple,
body[class*='-pu-'],
body[class*='category-how-you-can-help'],
body[class*='category-knowledge-hub'],
body[class*='category-our-education-and-residential-provision'],
body[class*='category-our-approach-to-learning'],
body[class*='what-young-people-learn'],
body[class*='pages-events-category-fundraising-events'] {
  @include category-colour($focus-colour: $_purple,
    $pebble-colour: "pebble_purple.svg",
    $pebble-mobile-colour: "carousel-mobile-pebble_purple.svg",
    $card-icon: "icon_multi.svg",
    $half-pebble-icon: "half-pebble_purple-darker.svg",
    $quote-colour: "quote-white.svg"
  );
}


.homeFeatures .homeFeature.homeFeatureNoBanner .homeFeatureDetailsWrapper,
.feedItem.feedItemNoBanner .feedItemDetailsWrapper,
.listedPost.listedPostNoBanner .listedPostText {

  h2,
  h3 {
    padding-right: 45px;
  }
}


// Blockquotes
blockquote {
  position: relative;
  z-index: 1;

  // Quote icon overall
  &:before,
  &:after {
    content: '';
    display: block !important;
    position: absolute;
    width: 30px;
    height: 25px;
    background-image: url($assets-path + 'quote-white.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &:before {
    // Quote left
    transform: rotate(180deg);
    left: 5px;
    top: 7.5px;
  }

  &:after {
    // Quote right
    right: 5px;
    bottom: 7.5px;
  }
}


// Client styles - Buttons
a.cta-button-blue {
  @include button ($btn-background-colour: $_sky-blue,
    $btn-text-colour: text-contrast($_sky-blue));
}

a.cta-button-green {
  @include button ($btn-background-colour: $_lime-green,
    $btn-text-colour: text-contrast($_lime-green));
}

a.cta-button-purple {
  @include button ($btn-background-colour: $_purple,
    $btn-text-colour: text-contrast($_purple));
}

a.cta-button-pink {
  @include button ($btn-background-colour: $_pink,
    $btn-text-colour: text-contrast($_pink));
}


// Subsite setup - Layout
body.subsite.homepage .contentContainer {

  .headerWrapperSubsite {
    order: -1;
    margin-bottom: $spacer * 0.5;
  }

  @media (min-width: #{$carousel-breakpoint + 1px}) {
    .subsiteTitle {
      color: text-contrast($_sky-blue);
    }
  }

  .homeFeeds {
    padding-top: $home-feeds-padding-top;
    padding-bottom: $home-feeds-padding-bottom;
  }

  .homeFeed {
    padding: 0;
    margin: 0;
  }

  .feedList {
    max-width: calc(#{$subsite-content-max-width} - #{$spacer * 4});
  }

  .feedsTitle {
    display: none;
  }

  .feedItem {
    position: relative;
    overflow: visible;
    border-left: 4px dotted #004a80;
    margin-left: 20px;

    [class*="DetailsWrapper"]>p {
      margin-bottom: $spacer;
    }

    a[class*='button'] {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -3px;
      left: 0;
      width: 40px;
      height: 40px;
      background-image: url($assets-path + "icon_multi.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transform: translateX(-55%);
    }
  }

  .homeFeed:last-of-type .feedItem {
    border-left: none;
  }

}

.homeFeatures .homeFeature .homeFeatureDetailsWrapper:before {
  height: 100% !important;
}

// QA
// IE fixes
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .homeFeatureDetailsWrapper:before {
    display: none;
  }
}


.post .appealActionsWrapper {
  margin: 0 auto $spacer;
}

.carouselSlideTitle img {
  height: 100%; 
  object-fit: cover;
}

.listing .tabsWrapper {
  display: none;
}

/* IM-931 - visual appeal xmas */

@include appeal-field("christmas-appeal-2021", "christmas");
@include appeal-field("christmas-appeal-2022", "christmas");
@include appeal-field("PostSystemCategory_christmas-appeal-style", "christmas");

body.appeal-christmas-appeal-2021,
body.appeal-christmas-appeal-2022,
body.PostSystemCategory_christmas-appeal-style {
  .menuMain {
    margin-bottom: 0;
  }
  .headerText {
    padding-top: 3rem;
    p, h1, h2, h3, h4, h5, h6 {
      max-width: 1230px;
      margin: 0 auto 1.5rem;
    }
  }
  article.post {
    background: transparent!important;
  }
  .post > .contentBlockWrapper:not(.formDonatePage):not(.appealActionsWrapper):after {
    display: none;
  }
}

.pages-Category-how-you-can-help .listedItem.listedPost.priors-court-family-backing-performing-arts-centre-fundraising-appeal.PostCategory_pi-family-experiences.module-pages {
  display: none;
}

.headerWrapper.headerWrapperSubsite .headerText.headerTextSubsite::before {
  background-image: url($assets-path + "pebble_pink.svg");
}

@media (max-width: map-get($breakpoints, lg)) {

  .carouselButtons .activeSlide a {
    background-color: white;
  }

}

// 642770 https://www.priorscourt.org.uk/blogs cards to be in Pink colour
.blogsListingPage .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) [class^="listed"][class$="Text"]
{
  background-color: #a8076f;
  &:hover {
    background-color: #a8076f;
  }
}